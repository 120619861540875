<template>
<div :style="{width: width, height: height}" style="border-radius: 10px; background: #FDFFFC;"
     class="tw-flex tw-flex-row  tw-items-center">
<img :src="img" alt="icon" class="img">
  <div class="tw-flex tw-w-auto tw-flex-col tw-p-5">
    <label class="title">{{title}}</label>
    <label class="desc tw-mt-2">{{desc}}</label>
  </div>
</div>
</template>

<script>
export default {
  name: "ImageAndText",
  props:{
    img : [String],
    title : [String],
    desc : [String],
    width : {
      type : [String],
      default : "191px"
    },
    height:{
      type : [String],
      default : "100px"
    }
  }
}
</script>

<style scoped lang="scss">
.img{
  width: 50%;
  height: 100%;
  //object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.title{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
  color: #4F4F4F;
}
.desc{
  font-family: Inter,sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 8px !important;
  line-height: 10px;
  color: #4F4F4F;

  @media screen and (max-width: 768px) {
    line-height: 24px !important;
  }
}
</style>