<template>
<div class="select-di">
<!--  <v-menu offset-y>-->
<!--    <template v-slot:activator="{ on, attrs }">-->
<!--      <div class="tw-flex tw-w-full tw-justify-between tw-px-1 tw-items-center" v-bind="attrs"-->
<!--           v-on="on">-->
<!--        <div class="filter text-capitalize">{{selectedFilter}}</div>-->
<!--        <v-icon>mdi-chevron-down</v-icon>-->
<!--      </div>-->
<!--    </template>-->
<!--    <v-list>-->
<!--      <v-list-item-->
<!--          v-for="(item, index) in filterTypes"-->
<!--          :key="index" @click="handleSelectedFilter(item)">-->
<!--        <v-list-item-title class="text-capitalize">{{ item }}</v-list-item-title>-->
<!--      </v-list-item>-->
<!--    </v-list>-->
<!--  </v-menu>-->
</div>
</template>

<script>
export default {
  name: "CustomSelect",
  data(){
    return{
      filterTypes:["daily","weekly","monthly","yearly"],
      selectedFilter: "daily"
    }
  },
  methods:{
    handleSelectedFilter(filter){
      this.selectedFilter = filter
      this.$emit('selectedFilter',filter)
    }
  }
}
</script>

<style scoped lang="scss">
.select-div{
  width: 90px;
  height: 26px;
  border: 1px solid rgba(187, 187, 187, 0.6);
  box-sizing: border-box;
  border-radius: 5px;
}

.filter{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px!important;
  line-height: 10px;
  color: #4F4F4F;
  height: 26px;
  border-right: 1px solid rgba(213, 214, 213, 0.2);
  width: 70%;
  align-items: center;
  display: flex;
}
</style>