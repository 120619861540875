<template>
  <div
    :style="{ width: width, height: height }"
    style="border-radius: 10px; background: #FDFFFC;"
    class="tw-flex tw-flex-row tw-p-3 tw-items-center tw-cursor-pointer tw-my-3 lg:tw-my-0"
    @click="$emit('clickAction')"
  >
    <img :src="icon" alt="icon" class="icon" />
    <div class="tw-flex tw-w-auto tw-flex-col tw-pl-3">
      <label class="title">{{ title }}</label>
      <label class="desc tw-mt-2">{{ desc }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconAndText",
  props: {
    icon: [String],
    title: [String],
    desc: [String],
    width: {
      type: [String],
      default: "191px",
    },
    height: {
      type: [String],
      default: "100px",
    },
  },
};
</script>

<style scoped lang="scss">
.icon {
  width: 48px;
  height: 48px;
}
.title {
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
  color: #4f4f4f;
}
.desc {
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  line-height: 10px;
  color: #4f4f4f;
}
</style>
