<template>
<div class="tw-flex tw-flex-col tw-p-5" style="border-radius: 10px; background: #FDFFFC;" :style="{width: width}">
<div class="tw-flex tw-flex-row tw-justify-between tw-p-2">
  <label class="title" :style="{color:companyColor}">{{title}}</label>

  <div class="tw-flex tw-flex-row">
    <city-select @selectedFilter="handleSelectedCity" v-show="showSelect" class="tw-mr-2"></city-select>
    <custom-select @selectedFilter="handleSelectedFilter " v-show="showSelect"/>
  </div>
  
</div>
  <div class="tw-flex tw-w-full" >
    <apexchart ref="terminalRef" :type="chartType" class="apex-style" :style="{color:companyColor?companyColor: '#19A2C7'}" :options="terminalChartOptions" :series="usageSeries"  height="230" v-show="usageCharts" />
    <apexchart ref="bookingRef" :type="chartType" class="apex-style" :style="{color:companyColor?companyColor: '#19A2C7'}" :options="chartOptions" :series="series"  height="230" v-show="bookingCharts" />
  </div>
</div>
</template>

<script>
import CustomSelect from "./CustomSelect";
import CitySelect from './CitySelect'
import {getCompanyBookingsPerDay, getCompanyTerminalStat} from "../../services/api/APIService";
import dayjs from "dayjs";
export default {
  name: "BookingChart",
  components: {CustomSelect, CitySelect},
  props:{
    usageCharts: {
      type: Boolean,
      default: false
    },
    bookingCharts: {
      type: Boolean,
      default: true
    },
    showSelect : {
      type: Boolean,
      default: false
    },
    seriesData:[],
    xAxisData:[],
    width : [String],
    companyColor :{
      type : [String],
      default: "#004AAD"
    },
    title : [String],
    chartType:{
      type: [String],
      default: 'bar'
    }

  },
  data(){
    return {
      selectedFilter: "",
      selectedCity: "",

      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          height: '100%',
          fontFamily : 'Inter, serif'
        },
        width: '2x',
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          lineCap: 'round',
          width: '1'
        },
        xaxis: {
          type: 'Daily',
          categories: []
        },
        noData: {
          text: 'Loading...'
        },
        legend:{
          itemMargin:{
            horizontal:1
          }
        },
        grid:{
          borderColor: 'rgba(79, 79, 79, 0.07)'
        },
        colors: [this.companyColor,'#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
        fill:{
          colors:['#FFFFFF'],
          opacity:0
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return val.toFixed(0)
            }
          },
        }
      },
      series: [{
        name: 'bookings',
        data: []
      }],
      terminalChartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          height: '100%',
          fontFamily : 'Inter, serif'
        },
        width: '2x',
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          lineCap: 'round',
          width: '1'
        },
        xaxis: {
          type: 'Daily',
          categories: []
        },
        noData: {
          text: 'Loading...'
        },
        legend:{
          itemMargin:{
            horizontal:1
          }
        },
        grid:{
          borderColor: 'rgba(79, 79, 79, 0.07)'
        },
        colors: [this.companyColor,'#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
        fill:{
          colors:['#FFFFFF'],
          opacity:0
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return val.toFixed(0)
            }
          },
        }
      },
      usageSeries: [{
        name: 'bookings',
        data: []
      }],
    }
  },
  methods:{
    handleSelectedFilter(filter) {
      this.selectedFilter = filter
    },
    handleSelectedCity(filter) {
      this.selectedCity = filter
    },
      sortObjectByDateKey(obj) {
          const arr = Object.entries(obj);
          arr.sort((a, b) => new Date(a[0]) - new Date(b[0]));
          return Object.fromEntries(arr);
      },
   async getBookingsPerDay(id){
      let xaxis = []
     let yaxis = []
      await  getCompanyBookingsPerDay(id).then(res =>{
        for(const [key,value] of Object.entries(this.sortObjectByDateKey(res.data))){
         let theKey = dayjs(key).format("ll")
          xaxis.push(theKey)
          yaxis.push(value)
        }
        if (yaxis.length >6){
          this.series[0].data = yaxis.slice(-6)
        }else {
          this.series[0].data = yaxis
        }
        if (xaxis.length > 6){
          this.chartOptions={...this.chartOptions,...{
              xaxis: {
                categories: xaxis.slice(-6)
              }
            }}
        }
        else {
          this.chartOptions={...this.chartOptions,...{
              xaxis: {
                categories: xaxis
              }
            }}
        }

        this.updateSeriesLine()
      }).catch(err=> {
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      })
    },
    async getTerminalsBookings(id){
      let xaxis = []
      let yaxis = []
      await getCompanyTerminalStat(id).then(res =>{
        for(const [key,value] of Object.entries(res.data)){
          let theKey = key.length > 6 ? key.substring(0,6) : key
          xaxis.push(theKey)
          yaxis.push(value.totalBookings)
        }
        if (yaxis.length >6){
          this.usageSeries[0].data = yaxis.slice(-6)
        }else {
          this.usageSeries[0].data = yaxis
        }
        if (xaxis.length > 6){
          this.terminalChartOptions={...this.terminalChartOptions,...{
              xaxis: {
                categories: xaxis.slice(-6)
              }
            }}
        }
        else {
          this.terminalChartOptions={...this.terminalChartOptions,...{
              xaxis: {
                categories: xaxis
              }
            }}
        }
        this.updateTerminalsSeriesLine();
      }).catch(err=> {
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      })
    },
    updateSeriesLine() {
      this.$refs.bookingRef.updateSeries([{
        data: this.series[0].data,
      }], false, true);
    },
    updateTerminalsSeriesLine() {
      this.$refs.terminalRef.updateSeries([{
        data: this.usageSeries[0].data,
      }], false, true);
    },
  },
 async mounted() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"))
    if (companyData){
     await this.getBookingsPerDay(companyData.id)
      await this.getTerminalsBookings(companyData.id)
    }
  }
}
</script>

<style scoped lang="scss">
.title{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
}

.apex-style{
  width: 100% !important;
  height: available !important;
  //color: #19A2C7!important;
}
</style>